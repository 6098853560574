import React from 'react'
import './reset.css'
import './global.css'

export const Layout = ({ children }) => {
  return (
    <>
      {children}
    </>
  )
};
